<template>
  <a-modal
    :title="handle === 'add' ? '新增' : '编辑'"
    style="top: 8px"
    :width="1000"
    v-model="visible"
    @ok="toSubmit"
    okText="提交"
    :maskClosable="false"
  >
    <a-form-model
      ref="form"
      :model="rowData"
      :rules="formRule"
      layout="horizontal"
      :label-col="{ span: 3 }"
      :wrapper-col="{ span: 21 }"
    >
      <a-form-model-item label="经销商ID" prop="(dealerId">
        <a-input v-model="rowData.dealerId" placeholder="经销商ID"></a-input>
      </a-form-model-item>
      <a-form-model-item label="营业执照" prop="(businessLic">
        <a-input v-model="rowData.businessLic" placeholder="营业执照"></a-input>
      </a-form-model-item>
      <a-form-model-item label="授权证书" prop="(authBook">
        <a-input v-model="rowData.authBook" placeholder="授权证书"></a-input>
      </a-form-model-item>
      <a-form-model-item label="公司logo" prop="(companyLogo">
        <a-input v-model="rowData.companyLogo" placeholder="公司logo"></a-input>
      </a-form-model-item>
      <a-form-model-item label="公司描述" prop="(describe">
        <a-input v-model="rowData.describe" placeholder="公司描述"></a-input>
      </a-form-model-item>
      <a-form-model-item label="业务介绍" prop="(intro">
        <a-input v-model="rowData.intro" placeholder="业务介绍"></a-input>
      </a-form-model-item>
      <a-form-model-item label="经营品牌" prop="(brand">
        <a-input v-model="rowData.brand" placeholder="经营品牌"></a-input>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import { editDealerCompanyInfo, selectByIdDealerCompanyInfo, addDealerCompanyInfo } from '../api/DealerCompanyInfoApi'
export default {
  data() {
    return {
      visible: false,
      handle: 'add',
      rowData: {},
      // 表单验证
      formRule: {
        dealerId: [{ required: true, message: '请输入经销商ID', trigger: 'blur' }],
        businessLic: [{ required: true, message: '请输入营业执照', trigger: 'blur' }],
        authBook: [{ required: true, message: '请输入授权证书', trigger: 'blur' }],
        companyLogo: [{ required: true, message: '请输入公司logo', trigger: 'blur' }],
        describe: [{ required: true, message: '请输入公司描述', trigger: 'blur' }],
        intro: [{ required: true, message: '请输入业务介绍', trigger: 'blur' }],
        brand: [{ required: true, message: '请输入经营品牌', trigger: 'blur' }],
      },
    }
  },
  methods: {
    /**
     * 获取行数据
     */
    setRowData(row, handle) {
      this.handle = handle
      this.visible = true
      this.rowData = row
    },
    /**
     * 表单提交
     */
    toSubmit() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return
        }
        const res =
          this.handle === 'add' ? await addDealerCompanyInfo(this.rowData) : await editDealerCompanyInfo(this.rowData)
        if (res.code === 200) {
          this.$notification.success({ message: res.message })
          this.$emit('reload')
          this.visible = false
          this.rowData = {}
        } else {
          this.$notification.error({ message: res.message })
        }
      })
    },
  },
  created() {},
}
</script>

<style lang="less" scoped>
</style>
